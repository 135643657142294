import $ from 'jquery';
import whatInput from 'what-input';
import shareThis from 'share-this';
import * as twitterSharer from "share-this/dist/sharers/twitter";
import * as facebookSharer from "share-this/dist/sharers/facebook";
import * as linkedinSharer from "share-this/dist/sharers/linked-in";
//import * as emailSharer from "share-this/dist/sharers/email";
import Clipboard from 'clipboard';

window.$ = $;


import Foundation from 'foundation-sites';
// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';

import 'tablesaw/dist/tablesaw.jquery';
import libs from './lib/dependencies';
window.libs = libs;

Foundation.Abide.defaults.patterns['min20'] = /.{20,}/;
Foundation.Abide.defaults.patterns['hitest'] = /^[Ww][iI][Mm]$/;
$(document).foundation();

libs.AOS.init();


// Share this
const selectionShare = shareThis({
    //sharers: [ twitterSharer, facebookSharer, linkedinSharer, emailSharer ]
    sharers: [ twitterSharer, facebookSharer, linkedinSharer ]
});

selectionShare.init();


// SVG Injector
// Elements to inject
var mySVGsToInject = document.querySelectorAll('img.inject-me');

// Options
var injectorOptions = {
  evalScripts: 'once',
  pngFallback: 'assets/png'
};

var afterAllInjectionsFinishedCallback = function (totalSVGsInjected) {
  // Callback after all SVGs are injected
  console.log('We injected ' + totalSVGsInjected + ' SVG(s)!');
};

var perInjectionCallback = function (svg) {
  // Callback after each SVG is injected
  console.log('SVG injected: ' + svg);
};

// create injector configured by options
var injector = new libs.svgInjector(injectorOptions);

// Trigger the injection
injector.inject(
  mySVGsToInject,
  afterAllInjectionsFinishedCallback,
  perInjectionCallback
);

// slick carousel
$(".content-carousel").slick({
  // normal options...
  speed: 5000,
	autoplay: true,
	autoplaySpeed: 0,
	cssEase: 'linear',
  slidesToShow: 5,
	slidesToScroll: 1,
  infinite: true,
  swipeToSlide: true,
	centerMode: true,
  focusOnSelect: true,
  // the magic
  responsive: [{
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        infinite: true
      }
    }, {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        dots: true
      }
    }, {
      breakpoint: 300,
      settings: "unslick" // destroys slick
    }]
});

// tablesaw table plugin
$(function () {
  $(document)
    .foundation()
    .trigger('enhance.tablesaw');
});

var TablesawConfig = {
  swipeHorizontalThreshold: 15
};

// app dashboard toggle
$('[data-app-dashboard-toggle-shrink]').on('click', function(e) {
  e.preventDefault();
  $(this).parents('.app-dashboard').toggleClass('shrink-medium').toggleClass('shrink-large');
});


// clipboard voor contactpagina

var clip = new Clipboard('.btn2clip');

var target = document.getElementById('clipemailbutton') 
clip.on("success", function() {
  //document.body.insertAdjacentHTML('beforeend', '<div>that worked.</div>');
  target.insertAdjacentHTML("afterend", '<div class="cell shrink showok"><p>OK</p></div>');
});
clip.on("error", function() {
  document.body.insertAdjacentHTML('beforeend', '<div>that didn\'t work.</div>');
});


